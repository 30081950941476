

















































































import { Component, Vue, Watch } from "vue-property-decorator"
import topNav from "@/components/topNav.vue"
import { Swiper as SwiperClass, Pagination, Autoplay, Navigation, EffectFade } from "swiper/core"
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter"
import "swiper/swiper-bundle.min.css"
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)
SwiperClass.use([Pagination, Autoplay, Navigation, EffectFade])
import 'vant/lib/index.css';
import {Toast } from 'vant';
@Component({
	components: {
		topNav,
		Swiper,
		SwiperSlide
	}
})
export default class Default extends Vue {
	swiperOption: any = {
		centeredSlides: true,
		slidesPerView: "auto",
		loop: true,
		pagination: {
			el: ".swiper-pagination",
			type: "fraction"
		}
	}
	swiperList: any = {}
	goodsDetailsData: any = {}
	accountNum = 1
	payPageShow = false
	balloonsMessage = ""
	showBalloons = false
	isCanNext = true
  priceShowStyle:any = 1

	@Watch("name")
	getWatchValue(newVal: string, oldVal: string) {
		console.log(newVal, oldVal)
	}

	created() {
    this.priceShowStyle=this.$route.query.priceShowStyle; // 3为积分+金额商品
    if(this.priceShowStyle==3) {
      this.goodsDetailsJifenjine()
    } else {
      this.goodsDetails()
    }
	}

	mounted() {
    console.log(localStorage.getItem('na'),location.href,222);
  }
 openShareBox(){
    const u: string = navigator.userAgent
		const isAndroid: boolean = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1 //android安卓
		const isiOS: boolean = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios苹果
    var url = window.location.href;
    var domain = "https://m.esb.test-01.54test.cn";
    if(url.includes("eshebao")){
         domain = "https://m.eshebao.cn"
    }
    var goGoodsDetailUrl= domain+`/Home/PointsGoodsDetail/${this.$route.params.id}?priceShowStyle=${this.priceShowStyle}`
		var Description = "";
		if(this.goodsDetailsData.priceShowStyle==3){
       Description=`${this.goodsDetailsData.salePoints}积分+${this.goodsDetailsData.esbSalePrice}元`
		}else if(this.goodsDetailsData.priceShowStyle==2){
       Description=`${this.goodsDetailsData.originalPoints}积分`
		}else if(this.goodsDetailsData.priceShowStyle==1){
       Description=`${this.goodsDetailsData.originalPrice}元`
		}else{
       Description=`${this.goodsDetailsData.salePoints}积分`
		}
    var obj = {
      title:this.goodsDetailsData.priceShowStyle==3?this.goodsDetailsData.name:this.goodsDetailsData.goodsName,
      description:Description,
      img:this.goodsDetailsData.coverPic,
      href:goGoodsDetailUrl
    }
     if (isAndroid) {
          ;(window as any).eshebao.gotoShare(JSON.stringify(obj));
      } else if (isiOS) {
          ;(window as any).webkit.messageHandlers.gotoShare.postMessage(JSON.stringify(obj));
      }
  }
	beforeDestroy() {
		if (sessionStorage.getItem("isOrder") && sessionStorage.getItem("isOrder") == "true") {
			sessionStorage.removeItem("isOrder")
			this.$router.push("/")
		}
	}

	goodsDetails() {
    return new Promise((resolve)=>{
      this.$api.goodsDetails(this.$route.params.id).then((res: any) => {
        this.goodsDetailsData = res.data
        if (res.data.publishStatus == "0") {
				  this.isCanNext = false
			  }
        this.swiperList = res.data.albumPics
        resolve(true);
      })
    })
	}
  goodsDetailsJifenjine() {
    return new Promise((resolve)=>{
      this.$api.fuagoodsDetails(this.$route.params.id).then((res: any) => {
        this.goodsDetailsData = res.data
        if (res.data.publishStatusDesc != "已上架") {
          this.isCanNext = false
        }
        this.swiperList = res.data.albumPics
        resolve(true);
      })
    })
  }

	reduceAccountNum() {
		if (this.accountNum != 1) {
			this.accountNum--
		}
	}

	addAccountNum() {
    let exchangeNumber=this.goodsDetailsData.exchangeNumber;
    if(this.accountNum>=exchangeNumber) {
      this.accountNum=exchangeNumber;
      this.showToast('您一次下单最多可选数量为：'+exchangeNumber);
      return
    }
		this.accountNum++;
  }
  showToast(val:any) {
    this.showBalloons = true
    let data: any = [val, this.showBalloons]
    this.VueFunc.$emit("balloons", data)
    setTimeout(() => {
      this.showBalloons = false
      data = [val, this.showBalloons]
      this.VueFunc.$emit("balloons", data)
    }, 2000)
  }

	async toPay() {
		if (this.isCanNext) {
			if(this.priceShowStyle==3) {
        await this.goodsDetailsJifenjine()
      } else {
        await this.goodsDetails()
      }
      this.payPageShow = true
		} else {
			this.payPageShow = false
		}
	}

	async submitOrder() {
		if (this.isCanNext) {
      if(this.priceShowStyle==3) {
        await this.goodsDetailsJifenjine()
      } else {
        await this.goodsDetails()
      }
      this.goToOrder()
    } else {
      this.payPageShow = false
    }
	}
  async goToOrder() {
    const obj={
      id: this.$route.params.id,
      count: this.accountNum
    }
    let res:any={};
    if(this.priceShowStyle==3) {
      res=await this.fuagoodsCheckstock(obj);
    } else {
      res=await this.checkstock(obj);
    }
    if(res.code == 200) {
      this.payPageShow = false
      this.$router.push({
        path: "/submitOrder",
        query: {
          key: res.data,
          goodsNum: this.accountNum.toString(),
          goodsDetailsData: encodeURIComponent(JSON.stringify(this.goodsDetailsData))
        }
      })
    }
	// else if(res.code==100&&this.priceShowStyle==3){
	// 	Toast.fail('您已兑换过此商品！');"商品库存不足"
	// }
	 else {
		Toast(res.msg)
    //   this.balloonsMessage = res.msg
    //   this.showBalloons = true
    //   let data: any = [this.balloonsMessage, this.showBalloons]
    //   this.VueFunc.$emit("balloons", data)
    //   setTimeout(() => {
    //     this.showBalloons = false
    //     data = [this.balloonsMessage, this.showBalloons]
    //     this.VueFunc.$emit("balloons", data)
    //   }, 2000)
    }
  }
  checkstock(data:any) {
    return new Promise((resolve)=>{
      this.$api.checkstock(data).then((res: any) => {
        resolve(res)
      })
    })
  }
  fuagoodsCheckstock(data:any) {
    return new Promise((resolve)=>{
      this.$api.fuagoodsCheckstock(data).then((res: any) => {
        resolve(res)
      })
    })
  }
	historyBack(){
		this.$router.push(`/`)
	}
}
